<template>
  <div>
    <vue-autosuggest
      ref="autocomplete"
      v-model="query"
      :suggestions="suggestions"
      :input-props="inputProps"
      :section-configs="sectionConfigs"
      :render-suggestion="renderSuggestion"
      :get-suggestion-value="getSuggestionValue"
      @keyup="searchCategories"
    />
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */
import { VueAutosuggest } from 'vue-autosuggest'
import { BCardText, BAvatar } from 'bootstrap-vue'

export default {
  components: {
    VueAutosuggest,
    BCardText,
    BAvatar,
  },
  data() {
    return {
      query: '',
      results: [],
      timeout: null,
      selected: null,
      childMessage: '',
      debounceMilliseconds: 250,
      usersUrl: 'http://localhost:4000/internal/category/search?client_id=605e4d6b3d79e12e5437985b',
      photosUrl: 'https://jsonplaceholder.typicode.com/photos',
      inputProps: {
        id: 'autosuggest__input_ajax',
        placeholder: 'Start typing a game or category :)',
        class: 'form-control',
        name: 'ajax',
      },
      suggestions: [],
      sectionConfigs: {
        categories: {
          limit: 50,
          label: '',
          onSelected: selected => {
            this.selected = selected.item
            this.$emit('childToParent', this.selected)
          },
        },
      },
    }
  },
  async mounted() {
    const channelData = await window.brime.getChannel()
    this.selected = channelData.category
    this.$emit('childToParent', this.selected)
    document.querySelector('#autosuggest__input_ajax').value = this.selected.name
  },
  methods: {
    searchCategories() {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(async () => {
        const categories = await window.brime.searchForCategory(this.query)
        this.suggestions = [
          { name: 'categories', data: categories },
        ]
      }, this.debounceMilliseconds)
    },
    filterResults(data, text, field) {
      return data.filter(item => {
        if (item[field].toLowerCase().indexOf(text.toLowerCase()) > -1) {
          return item[field]
        }
        return false
      }).sort()
    },
    renderSuggestion(suggestion) {
    //   if (suggestion.name === 'hotels') {
    //     const image = suggestion.item
      return (
          <div class="d-flex">
              <span>{suggestion.item.name}</span>
          </div>
      )
      //   }
    //   return suggestion.item.name
    },
    getSuggestionValue(suggestion) {
      const { name, item } = suggestion
      return name === 'hotels' ? item.title : item.name
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
pre{
  min-height: 295px;
  padding: 1.5rem;
  margin-bottom: 0;
  border-radius: .5rem;
}
</style>
