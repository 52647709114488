<template>
  <b-card>
    <b-form>
      <b-row>
        <b-col cols="12">
          <div class="d-flex align-items-center">
            <h4 class="mb-0 ml-75">
              Getting Started - Streaming on Brime
            </h4>
          </div>
        </b-col>
        <b-col cols="12">
          <hr class="">
          <div class="video-container">
            <iframe
              src="https://www.youtube.com/embed/zAApC8la9Xc"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BForm, BRow, BCol, BCard, // BCardText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BCard,
    // BCardText,
  },
  directives: {
    Ripple,
  },
  methods: {
    casterLink() {
      window.open('https://casterlabs.co/?auto_download=true')
    },
    resetForm() {
      this.localOptions = JSON.parse(JSON.stringify(this.socialData))
    },
  },
}
</script>
<style scoped>
.video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
}
.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>
