<template>
  <div>
    <b-card>
      <b-form>
        <b-row>
          <b-col cols="12">
            <div class="d-flex align-items-center mb-0">
              <h4 class="mb-0 ml-75">
                Brime Live - Stream Tools
              </h4>
            </div>
          </b-col>

          <b-col cols="12">
            <hr class="my-2"><a
              href="https://casterlabs.co/"
              target="_blank"
            >
              <img
                src="https://assets.casterlabs.co/logo/casterlabs_full_white.png"
                width="250"
              ></a><br>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              size="sm"
              @click.prevent="casterLink"
            >
              Download Casterlabs
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr class="my-2"><a
              href="https://brimestats.com"
              target="_blank"
            >
              <img
                src="https://content.brimecdn.com/brime/assets/brimeStats.png"
                width="300"
              ></a><br>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              size="sm"
              @click.prevent="brimeTrackerLink()"
            >
              Link Brin Chatbot
            </b-button>
          </b-col>
        <!--/ buttons -->
        </b-row>
      </b-form>
    </b-card>
    <b-card>
      <b-form>
        <b-row>
          <b-col cols="12">
            <div class="d-flex align-items-center mb-2">
              <feather-icon
                icon="LinkIcon"
                size="18"
              />
              <h4 class="mb-0 ml-75">
                OBS Browser Docks
              </h4>
            </div>
            <hr class="my-2">
          </b-col>
          <b-col cols="12" class="mb-1">
            <div class="d-flex">

              <!-- form input -->
              <b-form-group class="mb-0 mr-1">
                <h4 style="margin-top: 0.5rem;">
                  Chat Widget
                </h4>
              </b-form-group>

              <!-- button -->
              <b-button
                v-clipboard:copy="chatWidget"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="primary"
                size="sm"
              ><feather-icon
                icon="CopyIcon"
              />
                Copy
              </b-button>
            </div>
          </b-col>
          <b-col cols="12">
            <div class="d-flex">

              <!-- form input -->
              <b-form-group class="mb-0 mr-1">
                <h4 style="margin-top: 0.5rem;">
                  Stream Widget
                </h4>
              </b-form-group>

              <!-- button -->
              <b-button
                v-clipboard:copy="streamWidget"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="primary"
                size="sm"
              ><feather-icon
                icon="CopyIcon"
              />
                Copy
              </b-button>
            </div>
          </b-col>
        <!--/ buttons -->
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  BForm, BRow, BCol, BCard, BButton, BFormGroup, // BCardText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BCard,
    BButton,
    BFormGroup,
    // BCardText,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  props: {
    socialData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      chatWidget: null,
      streamWidget: 'https://beta.brimelive.com/obs-widgets/stream',
    }
  },
  async mounted() {
    this.userData = await window.brime.getUser()
    this.chatWidget = `https://beta.brimelive.com/${this.userData.username}/chat`
  },
  methods: {
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          variant: 'success',
          title: 'Widget copied',
          icon: 'CopyIcon',
          solid: true,
        },
      })
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Failed to copy texts!',
          icon: 'BellIcon',
        },
      })
    },
    casterLink() {
      window.open('https://casterlabs.co/?auto_download=true')
    },
    async brimeTrackerLink() {
      const brimeTrackerHash = (await window.BrimeUtils.sendHttpJson(
        `${window.brime.apiBase}/internal/auth/link/brimestats`,
        window.brime.clientId,
        window.brime.getAccessToken(),
        'POST',
        {},
      ))
      window.open(brimeTrackerHash.redirectLink)
    },
    resetForm() {
      this.localOptions = JSON.parse(JSON.stringify(this.socialData))
    },
  },
}
</script>
