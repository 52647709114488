<template>
  <div>
    <div>
      <h4>Multistream Settings</h4>
      <hr class=""><br>
      <b-form
        ref="form"
        :style="{height: trHeight}"
        class="repeater-form"
        @submit.prevent="repeatAgain"
      >

        <!-- Row Loop -->
        <b-row
          v-for="(item, index) in items"
          :id="item.id"
          :key="item.id"
          ref="row"
          class="service-row"
        >
          <!-- Item Name -->
          <b-col md="3">
            <b-form-group
              label="Platform"
              label-for="platform"
            >
              <b-form-select
                id="platform"
                class="repopulate-target"
                :value="item.platform"
                :options="options"
                @change="repopulate(); submitAll();"
              />
            </b-form-group>
          </b-col>

          <!-- Cost -->
          <b-col md="4">
            <b-form-group
              label="Stream Key"
              label-for="streamkey"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="streamkey"
                  :value="item.streamkey"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  placeholder="Paste stream key here"
                  @change="submitAll"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- Profession -->
          <b-col
            lg="2"
            md="1"
          >
            <b-form-group
              label="Enable/Disable"
              label-for="enableddisable"
            >
              <b-form-checkbox
                class="custom-control-success"
                :checked="item.enabled"
                name="check-button"
                switch
                inline
                style="margin-top: 7px;"
                @change="submitAll"
              />
            </b-form-group>
          </b-col>

          <!-- Remove Button -->
          <b-col
            lg="2"
            md="3"
            class="mb-50"
          >
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mt-0 mt-md-2"
              @click="removeItem(index)"
            >
              <feather-icon
                icon="Trash2Icon"
              />
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>

      </b-form>
    </div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      class="repeat-button"
      @click="repeatAgain"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      <span>Add Stream Destination</span>
    </b-button>
  </div>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton, BFormSelect, BFormCheckbox, BInputGroupAppend, BInputGroup,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility, heightTransition],
  data() {
    return {
      selected: null,
      options: [
        { value: 'DLIVE', text: 'DLive' },
        { value: 'FACEBOOK', text: 'Facebook' },
        { value: 'TROVO', text: 'Trovo' },
        { value: 'TWITCH', text: 'Twitch' },
        { value: 'TWITTER', text: 'Twitter' },
        { value: 'YOUTUBE', text: 'YouTube' },
      ],
      validPlatforms: {
        DLIVE: 'DLive',
        FACEBOOK: 'Facebook',
        TROVO: 'Trovo',
        TWITCH: 'Twitch',
        TWITTER: 'Twitter',
        YOUTUBE: 'YouTube',
      },
      items: [],
      nextTodoId: 0,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  async beforeMount() {
    const repubs = await window.brime.getRepubs()

    let currentId = 1

    repubs.forEach(repub => {
      this.items.push({
        id: currentId,
        platform: repub.platform,
        streamkey: repub.streamkey,
        enabled: repub.enabled,
      })

      currentId += 1

      this.nextTickTrAddHeight()
    })

    this.nextTodoId = currentId

    this.repopulate()

    // console.debug(repubs)
    // console.debug(this.items)
  },
  mounted() {
    this.initTrHeight()
    window.addEventListener('resize', this.initTrHeight)
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    getUpdatedItems() {
      const items = []

      const elements = Array.from(document.querySelectorAll('.service-row'))

      elements.forEach(e => {
        const ePlatform = e.querySelector('#platform')
        const eStreamkey = e.querySelector('#streamkey')
        const eEnable = e.querySelector('input[type=checkbox]')

        const platform = ePlatform.value
        const streamkey = eStreamkey.value
        const enabled = eEnable.checked

        // Initially unset.
        if (platform.length > 0) {
          items.push({
            id: items.length + 1,
            platform,
            streamkey,
            enabled,
          })
        }
      })

      document.querySelector('.repeat-button').disabled = (elements.length >= 6)

      return items
    },
    async submitAll() {
      const toDelete = []
      const toCreate = []
      const toUpdate = []

      const existingRepubs = {}
      const items = {};

      (await window.brime.getRepubs()).forEach(repub => {
        existingRepubs[repub.platform] = repub
      })

      this.getUpdatedItems().forEach(item => {
        items[item.platform] = item
      })

      Object.keys(items).forEach(platform => {
        if (existingRepubs[platform]) {
          toUpdate.push(platform)
        } else {
          toCreate.push(platform)
        }
      })

      Object.keys(existingRepubs).forEach(platform => {
        if (!items[platform]) {
          toDelete.push(platform)
        }
      })

      const apiPromises = []

      toDelete.forEach(platform => {
        apiPromises.push(window.brime.deleteRepub(platform))
      })

      toCreate.forEach(name => {
        const platform = items[name]

        apiPromises.push(window.brime.createRepub(name, platform.streamkey))
      })

      toUpdate.forEach(name => {
        const platform = items[name]

        apiPromises.push(window.brime.updateRepub(name, platform.enabled, platform.streamkey))
      })

      // console.debug('')
      // console.debug('form', items)
      // console.debug('existing', existingRepubs)
      // console.debug('Calculated delta:')
      // console.debug('toDelete', toDelete)
      // console.debug('toCreate', toCreate)
      // console.debug('toUpdate', toUpdate)

      Promise.all(apiPromises).then(() => {
        this.$bvToast.toast('Multistream settings updated.', {
          title: 'Success',
          variant: 'success',
          solid: true,
        })
      })
        .catch(() => {
          this.$bvToast.toast('An error occured while updating multistream settings, please contact support.', {
            title: 'Error.',
            variant: 'danger',
            solid: true,
          })
        })
    },
    repopulate() {
      const platforms = {}

      Object.keys(this.validPlatforms).forEach(key => {
        platforms[key] = false
      })

      this.getUpdatedItems().forEach(item => {
        platforms[item.platform] = true
      })

      Object.keys(this.validPlatforms).forEach(platform => {
        const disabled = platforms[platform]

        const elements = Array.from(document.querySelectorAll(`option[value=${platform}]`))

        // console.debug(`option[value=${platform}]`, elements)

        elements.forEach(e => {
          if (disabled) {
            e.disabled = true
          } else {
            e.removeAttribute('disabled')
          }
        })
      })
    },
    repeatAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
        enabled: true,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        this.repopulate()
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
      this.repopulate()
      this.submitAll()
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    nextTickTrAddHeight() {
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
