<template>
  <div>
    <b-card style="max-width: 700px">
      <!-- form -->
      <b-form>
        <b-row>
          <!-- bio -->
          <b-col cols="12">
            <b-form-group
              label="Stream Title"
              label-for="bio-area"
            >
              <b-form-input
                id="title"
                v-model="form.title"
                rows="1"
                placeholder="owning Puppet in Valorant!"
                maxlength="80"
              />
            </b-form-group>
            <b-form-group
              label="Category"
              label-for="bio-area2"
            >
              <AutoSuggestAjax @childToParent="onChildClick" />
            </b-form-group>
          </b-col>
          <!--/ bio -->
          <!--/ website -->

          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="mt-1 mr-1"
              @click.prevent="submitForm"
            >
              Save changes
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              size="sm"
              class="mt-1"
              variant="outline-secondary"
              @click.prevent="resetForm"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form></b-card>
    <b-card style="max-width: 700px">
      <h4 class="mb-1">
        VOD Settings</h4>
      <b-form-checkbox
        id="enableVods"
        v-model="form.enableVods"
        checked="true"
        name="enableVods"
        class="mb-1"
        switch
        inline
        @change="vodToggle"
      >
        Store past broadcasts
      </b-form-checkbox><br>Automatically save broadcasts for up to 14 days (60 days for <router-link to="/BrimePro">
        <strong>Brime Pro</strong>
      </router-link> users)
    </b-card>
    <b-card
      style="max-width: 700px"
      border-variant="primary"
    >
      <b-form-group
        label=""
        label-for="bio-area2"
      >
        <h4><b-avatar
          size="40"
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d3/OBS_Studio_Logo.svg/1200px-OBS_Studio_Logo.svg.png"
          variant="light-primary"
          class="badge-minimal channelAvi"
          style="margin-right: 1em;"
        />
          Brime is now included in the latest release of OBS Studio.
        </h4>
        <br>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          size="sm"
          variant="primary"
          style="margin-top: 5px; margin-right: 10px;"
          href="https://cdn-fastly.obsproject.com/downloads/OBS-Studio-27.0-Full-Installer-x64.exe"
          target="_blank"
        >
          Windows
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          size="sm"
          variant="primary"
          style="margin-top: 5px; margin-right: 10px;"
          href="https://cdn-fastly.obsproject.com/downloads/obs-mac-27.0.dmg"
          target="_blank"
        >
          MacOS
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          size="sm"
          variant="primary"
          style="margin-top: 5px;"
          href="https://obsproject.com/wiki/install-instructions#linux"
          target="_blank"
        >
          Linux
        </b-button>
      </b-form-group></b-card>
    <b-card
      style="max-width: 700px"
      border-variant="primary"
    >
      <h4 class="mb-1">
        Stream Key
      </h4>
      <b-button
        v-clipboard:copy="stream.streamKey"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError"
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="primary"
        size="sm"
        class="mr-1"
      > <feather-icon
          icon="CopyIcon"
          class="mr-50 pt-0 pb-0"
        />
        <span
          class="align-middle"
          style="line-height:14px;"
        >Copy</span>
      </b-button>
      <b-button
        variant="danger"
        size="sm"
        @click="resetStreamKey"
      >
        <feather-icon
          icon="RefreshCwIcon"
          class="mr-50 pt-0 pb-0"
        />
        <span
          class="align-middle"
          style="line-height:14px;"
        >Reset</span>
      </b-button>
    </b-card>
  </div>
</template>

<script>
import {
  BFormCheckbox,
  BButton,
  BForm,
  BFormGroup,
  BRow,
  BCol,
  BCard,
  BFormInput,
  BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AutoSuggestAjax from './Test.vue'

export default {
  components: {
    BFormInput,
    BButton,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    AutoSuggestAjax,
    BFormCheckbox,
    BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  props: {
    informationData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      fromChild: '',
      datasuggest: [],
      filteredOptions: [],
      limit: 10,
      selected: null,
      onboardIframe: '',
      form: {
        title: '',
        category: '',
        enableVods: true,
      },
      stream: {
        server: 'Check #dev-updates in the Discord',
        streamKey: '',
      },
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  beforeCreate() {
    // this.$http.get('').then(res => { this.options = res.data.streams })
    window.brime.getChannel()
      .then(async response => {
        const userData = await window.brime.getUser()
        const {
          title, category, vodsEnabled,
        } = response

        this.form.title = title
        // eslint-disable-next-line no-underscore-dangle
        this.form.category = category.name
        this.form.enableVods = vodsEnabled
        // eslint-disable-next-line no-underscore-dangle
        this.stream.streamKey = `${response._id}?auth=${userData.streamKey}`
      })
  },
  /* eslint-disable */
  methods: {
    resetStreamKey(){
  this.$bvToast.toast("THIS HAS NOT BEEN ENABLED YET. SOON™", {
  title: 'Stream Key Reset',
   variant: 'danger',
   solid: true,
  })
    },
    onCopy() {
  this.$bvToast.toast("Stream key copied to clipboard.", {
  title: 'Stream Key',
   variant: 'success',
   solid: true,
  })
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Failed to copy texts!',
          icon: 'BellIcon',
        },
      })
    },
    onChildClick (value) {
      this.fromChild = value
    },
    makeToast(message) {
      this.$bvToast.toast(message, {
        title: 'Error uploading image.',
        variant: 'danger',
        solid: true,
      })
    },
    async submitForm() {
      let config = {
  headers: {
    Authorization: 'Bearer ' + window.localStorage.getItem('accessToken'),
  }
}
      axios.post('https://api-staging.brimelive.com/internal/channel/update?client_id=605e4d6b3d79e12e5437985b', {
    title: this.form.title,
    category: this.fromChild._id,
    vodsEnabled: this.form.enableVods,
    description:""
  }, config)
 .then((res) => {
    this.$bvToast.toast("Title and Category updated successfully.", {
  title: 'Success',
   variant: 'success',
   solid: true,
  })})
  .catch(function (errors) {
  const message = errors[0].split(":")[1]
  this.$bvToast.toast(message, {
    title: 'Error.',
    variant: 'danger',
    solid: true,
  })
  });
    },
        async vodToggle() {
      let config = {
  headers: {
    Authorization: 'Bearer ' + window.localStorage.getItem('accessToken'),
  }
}
      axios.post('https://api-staging.brimelive.com/internal/channel/update?client_id=605e4d6b3d79e12e5437985b', {
    vodsEnabled: this.form.enableVods,
  }, config)
 .then((res) => {
    this.$bvToast.toast("Vod Settings Updated", {
  title: 'Success',
   variant: 'success',
   solid: true,
  })})
  .catch(function (errors) {
  const message = errors[0].split(":")[1]
  this.$bvToast.toast(message, {
    title: 'Error.',
    variant: 'danger',
    solid: true,
  })
  });
    },
    resetForm() {
      this.form = "";
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/pages/page-auth.scss";
</style>
