/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
<template>
  <div class="bod">
    <iframe
      id="myIframe"
      :src="onboardIframe.dashboard"
      style="overflow:hidden;height:100%;width:100%"
      height="100%"
      width="100%"
      frameBorder="0"
      scrolling="no"
      crossorigin="anonymous"
    />
  </div>
</template>

<script>
import {
  BForm, BRow, BCol, BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

/* eslint-disable */
export default {
  components: {
    BForm,
    BRow,
    BCol,
    BCard,
  },
  directives: {
    Ripple,
  },
  props: {
    socialData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      onboardIframe: '',
    }
  },
  async beforeCreate() {
    this.onboardIframe = await window.brime.getTipaltiIframeUrls()
  },
  mounted() {
  },
  methods: {
    casterLink() {
      window.open('https://casterlabs.co/?auto_download=true')
    },
    resetForm() {
      this.localOptions = JSON.parse(JSON.stringify(this.socialData))
    },
  },
}
</script>
<style scoped>
.bod{
height: 100%;
}
iframe{
height: 2000px !important;
padding-bottom: 30px;
}
</style>
