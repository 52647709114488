<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- info -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="SettingsIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Stream Settings</span>
      </template>

      <account-setting-information />
    </b-tab>
    <!-- channel settings -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="SettingsIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Channel Settings</span>
      </template>

      <account-channel-settings />
    </b-tab>
    <!-- emote settings
    <b-tab lazy>
      <template #title>
        <feather-icon
          icon="ImageIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Channel Emotes</span>
      </template>

      <Emotes />
    </b-tab>-->
    <!-- Casterlabs Tools -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Getting Started</span>
      </template>

      <getting-started />
    </b-tab>
    <!-- Casterlabs Tools -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="ToolIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Stream Tools</span>
      </template>

      <account-setting-social />
    </b-tab>
    <b-tab lazy>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="Share2Icon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Multi-Streaming</span>
      </template>

      <Multistreaming />
    </b-tab>
    <b-tab
      v-if="monetized"
      lazy
    >
      <!-- title -->
      <template #title>
        <feather-icon
          icon="DollarSignIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Monetization</span>
      </template>

      <Monetization />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import AccountSettingInformation from './AccountSettingInformation.vue'
import AccountSettingSocial from './AccountSettingSocial.vue'
import AccountChannelSettings from './AccountChannelSettings.vue'
import GettingStarted from './GettingStarted.vue'
import Monetization from './Monetization.vue'
import Multistreaming from './Multistreaming.vue'
// import Emotes from './Emotes.vue'
// import AccountSettingNotification from './AccountSettingNotification.vue'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingInformation,
    AccountSettingSocial,
    AccountChannelSettings,
    GettingStarted,
    Monetization,
    Multistreaming,
    // Emotes,
  },
  data() {
    return {
      creatorStatus: '',
      userData: '',
      monetized: false,
      options: {},
    }
  },
  // eslint-disable-next-line no-empty-function
  async mounted() {
  },
  async beforeCreate() {
    this.userData = await window.brime.getUser()
    if (await this.userData.creatorStatus === 'ELITE') {
      this.monetized = true
    }
  },
}
</script>
